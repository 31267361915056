import React from 'react';
import "../styles/UnitDetails.css";
import { useTranslation } from 'react-i18next';

const RoomCard = ({ icon: Icon, roomName, roomSize }) => {
    const { t, } = useTranslation();
    return (
    <div className="room-card">
        <Icon className="room-icon" />
        <div className="room-details">
            <span>{roomName}</span>
            <span>
                {parseFloat(roomSize).toFixed(parseFloat(roomSize) % 1 === 0 ? 0 : 1)} {t('sqm')}
            </span>
        </div>
    </div>
)};

export default RoomCard;
