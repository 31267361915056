import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    FaBed, FaBath, FaCar, FaRulerCombined, FaUtensils, FaCouch, FaBlender, FaSoap,
    FaSun, FaDoorClosed, FaRoad, FaChair, FaBackward, FaGamepad, FaRulerVertical
} from 'react-icons/fa'; // Icons
import { motion, AnimatePresence } from 'framer-motion';
import "../styles/UnitDetails.css";
import { useTranslation } from 'react-i18next';
import RoomCard from '../components/RoomCard';
import { Carousel } from 'react-responsive-carousel';

const UnitDetailsPage = () => {
    const { unitId } = useParams(); // Get the unit ID from the URL
    const [unit, setUnit] = useState(null);
    const [isOpenMes, setIsOpenMes] = useState(false);
    const { t, i18n } = useTranslation();
    const MotionLink = motion(Link);
    const navigate = useNavigate();
    // const [zoom, setZoom] = useState(false);
    // const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (unitId) {
            fetch(`${process.env.REACT_APP_API_URL}/units/${unitId}/`) // Fetch unit details by ID
                .then((response) => response.json())
                .then((data) => setUnit(data))
                .catch((error) => console.error('Error fetching unit details:', error));
        }
    }, [unitId]);

    if (!unit) return <p>{t('loading')}</p>;

    const toggleOpenMes = () => {
        setIsOpenMes(!isOpenMes);
    };

    const isArabic = i18n.language === 'ar';
    return (
        <div>
            <button className='back' onClick={() => navigate(-1)}><FaBackward className='back-icon' /> {t('back')}</button>
            <div className="unit-details-page" dir={isArabic ? 'rtl' : 'ltr'} lang={i18n.language}>
                {/* Image Carousel */}
                <motion.div
                    className="unit-image-container"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                >
                    <div className="image-carousel">
                        <Carousel>
                            {unit.unit_images && unit.unit_images.map((image, index) => (
                                <div key={index}>
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}${image.image}`}
                                        alt={`Unit ${unit.id}`}
                                        className="unit-image"
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </motion.div>

                {/* Unit Name and Description */}
                <motion.div
                    className="unit-info"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <h1>{unit.code}</h1>
                    {unit.building.location && (
                        <p>{isArabic ? unit.building.location_arb : unit.building.location}</p>
                    )}
                    {unit.description && (
                        <p><strong>{t('unit-desc')}</strong> {isArabic ? unit.description_arb : unit.description}</p>
                    )}
                    <div>
                        <span className='unit-floor'>
                            {isArabic ? unit.apartment_floor_arb : unit.apartment_floor}
                        </span>
                        <span className='unit-condition'>
                            {isArabic ? unit.unit_condition_arb : unit.unit_condition}
                        </span>
                        <span
                            className={`unit-availability ${unit.status === "available" ? "available" : "not-available"}`}
                        >
                            {unit.status === "available" ? t('available') : t('not-available')}
                        </span>
                    </div>

                </motion.div>
                {/* Unit Metadata */}
                <motion.div
                    className="unit-meta"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    {unit.number_of_bedrooms && (
                        <div className="meta-item">
                            <FaBed className="meta-icon" />
                            <span>{unit.number_of_bedrooms} {t('unit-bedrooms')}</span>
                        </div>
                    )}
                    {unit.number_of_bathrooms && (
                        <div className="meta-item">
                            <FaBath className="meta-icon" />
                            <span>{unit.number_of_bathrooms} {t('unit-bathrooms')}</span>
                        </div>
                    )}
                    {unit.parking_spaces_per_unit && (
                        <div className="meta-item">
                            <FaCar className="meta-icon" />
                            <span>{unit.parking_spaces_per_unit} {t('unit-parking')}</span>
                        </div>
                    )}
                    {unit.height && (
                        <div className="meta-item">
                            <FaRulerVertical className="meta-icon" />
                            <span>{Number(unit.height).toFixed(0)} {t('unit-meters')}</span>
                        </div>
                    )}
                    <div className="meta-item">
                        <FaRulerCombined className="meta-icon" />
                        <span>{Number(unit.total_square_meters).toFixed(0)} {t('sqm')}</span>
                    </div>
                </motion.div>

                <div className="room-measurements-container">
                    {/* Room Measurements Section */}
                    <motion.div
                        className="room-measurements"
                        initial={{ opacity: 0, height: 0 }} // Start with no height and hidden
                        animate={{ opacity: 1, height: "auto" }} // Expand to full height and visible
                        exit={{ opacity: 0, height: 0 }} // Shrink height back to 0 when closing
                        transition={{ duration: 0.5 }}
                    >
                        <h2 onClick={toggleOpenMes} className="toggle-title">
                            {t('unit-rooms')}
                            <span className={`hamburger ${isOpenMes ? "open" : ""}`}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </h2>
                        <AnimatePresence>
                            {isOpenMes && (
                                <motion.div
                                    className="room-cards"
                                    initial={{ height: 0 }}
                                    animate={{ height: "auto" }}
                                    exit={{ height: 0 }}
                                    transition={{ duration: 0.4 }}
                                >
                                    {unit.dining_room_size && (
                                        <RoomCard icon={FaUtensils} roomName={t('unit-dining')} roomSize={unit.dining_room_size} />
                                    )}
                                    {unit.salon_size && (
                                        <RoomCard icon={FaChair} roomName={t('unit-salon')} roomSize={unit.salon_size} />
                                    )}
                                    {unit.kitchen_size && (
                                        <RoomCard icon={FaBlender} roomName={t('unit-kitchen')} roomSize={unit.kitchen_size} />
                                    )}
                                    {unit.laundry_room_size && (
                                        <RoomCard icon={FaSoap} roomName={t('unit-laundry')} roomSize={unit.laundry_room_size} />
                                    )}
                                    {unit.living_room_size && (
                                        <RoomCard icon={FaCouch} roomName={t('unit-living')} roomSize={unit.living_room_size} />
                                    )}
                                    {unit.bedroom_1_size && (
                                        <RoomCard icon={FaBed} roomName={t('unit-bed1')} roomSize={unit.bedroom_1_size} />
                                    )}
                                    {unit.bedroom_2_size && (
                                        <RoomCard icon={FaBed} roomName={t('unit-bed2')} roomSize={unit.bedroom_2_size} />
                                    )}
                                    {unit.bedroom_3_size && (
                                        <RoomCard icon={FaBed} roomName={t('unit-bed3')} roomSize={unit.bedroom_3_size} />
                                    )}
                                    {unit.bathroom_1_size && (
                                        <RoomCard icon={FaBath} roomName={t('unit-bath1')} roomSize={unit.bathroom_1_size} />
                                    )}
                                    {unit.bathroom_2_size && (
                                        <RoomCard icon={FaBath} roomName={t('unit-bath2')} roomSize={unit.bathroom_2_size} />
                                    )}
                                    {unit.bathroom_3_size && (
                                        <RoomCard icon={FaBath} roomName={t('unit-bath3')} roomSize={unit.bathroom_3_size} />
                                    )}
                                    {unit.balcony_1_size && (
                                        <RoomCard icon={FaSun} roomName={t('unit-balcony1')} roomSize={unit.balcony_1_size} />
                                    )}
                                    {unit.balcony_2_size && (
                                        <RoomCard icon={FaSun} roomName={t('unit-balcony2')} roomSize={unit.balcony_2_size} />
                                    )}
                                    {unit.balcony_3_size && (
                                        <RoomCard icon={FaSun} roomName={t('unit-balcony3')} roomSize={unit.balcony_3_size} />
                                    )}
                                    {unit.main_entrance_size && (
                                        <RoomCard icon={FaDoorClosed} roomName={t('unit-entrance')} roomSize={unit.main_entrance_size} />
                                    )}
                                    {unit.main_corridor_size && (
                                        <RoomCard icon={FaRoad} roomName={t('unit-main-corridor')} roomSize={unit.main_corridor_size} />
                                    )}
                                    {unit.corridor_size && (
                                        <RoomCard icon={FaRoad} roomName={t('unit-corridor')} roomSize={unit.corridor_size} />
                                    )}
                                    {unit.games_room_size && (
                                        <RoomCard icon={FaGamepad} roomName={t('unit-game')} roomSize={unit.games_room_size} />
                                    )}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </motion.div>
                </div>


                <div className="details-container">

                    {(unit.building.cooling_system || unit.building.sewer || unit.building.water ||

                        unit.building.electricity || unit.building.internet || unit.building.hot_water ||
                        unit.building.solar_system) && (
                            /* Utilities */
                            <div className="details-card">
                                <h2 className="details-title">{t('unit-util')}</h2>
                                <div className="details-content">
                                    {unit.building.cooling_system && (
                                        <p><strong>{t('unit-cooling')}</strong> {isArabic ? unit.building.cooling_system_arb : unit.building.cooling_system}</p>
                                    )}
                                    {unit.building.sewer && (
                                        <p><strong>{t('unit-sewer')}</strong> {isArabic ? unit.building.sewer_arb : unit.building.sewer}</p>
                                    )}
                                    {unit.building.water && (
                                        <p><strong>{t('unit-water')}</strong> {isArabic ? unit.building.water_arb : unit.building.water}</p>
                                    )}
                                    {unit.building.electricity && (
                                        <p><strong>{t('unit-elec')}</strong> {isArabic ? unit.building.electricity_arb : unit.building.electricity}</p>
                                    )}
                                    {unit.building.internet && (
                                        <p><strong>{t('unit-internet')}</strong> {isArabic ? unit.building.internet_arb : unit.building.internet}</p>
                                    )}
                                    {unit.building.hot_water && (
                                        <p><strong>{t('unit-hot-water')}</strong> {isArabic ? unit.building.hot_water_arb : unit.building.hot_water}</p>
                                    )}
                                    {unit.building.solar_system && (
                                        <p><strong>{t('unit-solar')}</strong> {isArabic ? unit.building.solar_system_arb : unit.building.solar_system}</p>
                                    )}
                                </div>
                            </div>
                        )}

                    {(unit.building.slab_type || unit.building.external_partition_type || unit.building.interior_partition_type ||
                        unit.building.partition_thickness || unit.building.flooring_type || unit.building.foundation_type) && (
                            /* Structural and Finish Details */
                            <div className="details-card">
                                <h2 className="details-title">{t('unit-structure')}</h2>
                                <div className="details-content">
                                    {unit.building.slab_type && (
                                        <p><strong>{t('unit-slab')}</strong> {isArabic ? unit.building.slab_type_arb : unit.building.slab_type}</p>
                                    )}
                                    {unit.building.external_partition_type && (
                                        <p><strong>{t('unit-external')}</strong> {isArabic ? unit.building.external_partition_type_arb : unit.building.external_partition_type}</p>
                                    )}
                                    {unit.building.interior_partition_type && (
                                        <p><strong>{t('unit-internal')}</strong> {isArabic ? unit.building.interior_partition_type_arb : unit.building.interior_partition_type}</p>
                                    )}
                                    {unit.building.partition_thickness && (
                                        <p><strong>{t('unit-partition')}</strong> {isArabic ? unit.building.partition_thickness_arb : unit.building.partition_thickness}</p>
                                    )}
                                    {unit.building.flooring_type && (
                                        <p><strong>{t('unit-floor')}</strong> {isArabic ? unit.building.flooring_type_arb : unit.building.flooring_type}</p>
                                    )}
                                    {unit.building.foundation_type && (
                                        <p><strong>{t('unit-foundation')}</strong> {isArabic ? unit.building.foundation_type_arb : unit.building.foundation_type}</p>
                                    )}
                                </div>
                            </div>
                        )}

                    {unit.building.exterior_finish && (
                        /* Exterior Features */
                        <div className="details-card">
                            <h2 className="details-title">{t('unit-ext-feat')}</h2>
                            <div className="details-content">
                                <p><strong>{t('unit-ext-finish')}</strong> {isArabic ? unit.building.exterior_finish_arb : unit.building.exterior_finish}</p>
                            </div>
                        </div>
                    )}
                    {(unit.apartment_direction || unit.building.elevation_from_sea_level ||
                        unit.building.landscape_features || unit.building.view_directions ||
                        unit.building.fencing_details
                    ) && (
                            /* Apartment Details */
                            <div className="details-card">
                                <h2 className="details-title">{t('unit-apartment-details')}</h2>
                                <div className="details-content">
                                    {unit.building.fencing_details && (
                                        <p>
                                            <strong>{t('unit-fence')}</strong> {isArabic ? unit.building.fencing_details_arb : unit.building.fencing_details}
                                        </p>
                                    )}
                                    {unit.apartment_direction && (
                                        <p>
                                            <strong>{t('unit-apartment-direction')}</strong> {isArabic ? unit.apartment_direction_arb : unit.apartment_direction}
                                        </p>
                                    )}
                                    {unit.building.elevation_from_sea_level && (
                                        <p>
                                            <strong>{t('unit-land-elevation')}</strong> {parseFloat(unit.building.elevation_from_sea_level).toFixed(parseFloat(unit.building.elevation_from_sea_level) % 1 === 0 ? 0 : 1)} {t('unit-meters')}
                                        </p>
                                    )}
                                    {unit.building.landscape_features && (
                                        <p>
                                            <strong>{t('unit-landscape-feature')}</strong> {isArabic ? unit.building.landscape_features_arb : unit.building.landscape_features}
                                        </p>
                                    )}
                                    {unit.building.view_directions && (
                                        <p>
                                            <strong>{t('unit-quality-view')}</strong> {t('unit-degree1')} {unit.building.view_directions} {t('unit-degree2')}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}

                    {unit.building.amenities_nearby && (
                        /* Neighborhood Features */
                        <div className="details-card">
                            <h2 className="details-title">{t('unit-neighborhood')}</h2>
                            <div className="details-content">
                                {unit.building.amenities_nearby && (
                                    <p>{isArabic ? unit.building.amenities_nearby_arb : unit.building.amenities_nearby}</p>
                                )}
                                {unit.building.main_road_nearby && (
                                    <p>
                                        <strong>{t('unit-road')}</strong> {isArabic ? unit.building.main_road_nearby_arb : unit.building.main_road_nearby}
                                    </p>
                                )}
                                {unit.building.education_nearby && (
                                    <p>
                                        <strong>{t('unit-education')}</strong> {isArabic ? unit.building.education_nearby_arb : unit.building.education_nearby}
                                    </p>
                                )}
                                {unit.building.authorities_nearby && (
                                    <p>
                                        <strong>{t('unit-authority')}</strong> {isArabic ? unit.building.authorities_nearby_arb : unit.building.authorities_nearby}
                                    </p>
                                )}
                                {unit.building.nature_features && (
                                    <p>
                                        <strong>{t('unit-nature')}</strong> {isArabic ? unit.building.nature_features_arb : unit.building.nature_features}
                                    </p>
                                )}
                                {unit.building.wellness_centers && (
                                    <p>
                                        <strong>{t('unit-wellness')}</strong> {isArabic ? unit.building.wellness_centers_arb : unit.building.wellness_centers}
                                    </p>
                                )}
                                {unit.building.restaurants_nearby && (
                                    <p>
                                        <strong>{t('unit-resto')}</strong> {isArabic ? unit.building.restaurants_nearby_arb : unit.building.restaurants_nearby}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='unit-get-in-touch'>
                <motion.div
                    className="get-in-touch-section"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <div className="text-content">
                        <h2>{t('touch-title1')} <span className="whitegreen-text">{t('touch-title2')}</span></h2>
                        <p>
                            {t('touch-desc')}
                        </p>
                    </div>
                    <MotionLink
                        to="/contact"
                        className={`contact-button ${isArabic ? 'rtl' : ''}`}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        style={{ direction: isArabic ? 'rtl' : 'ltr', textAlign: isArabic ? 'right' : 'left' }}
                    >
                        {t('touch-button')}
                    </MotionLink>
                </motion.div>
            </div>
        </div>
    );
};

export default UnitDetailsPage;
