import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

function Navbar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const closeNavbar = () => {
    setIsCollapsed(true);
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const isArabic = i18n.language === 'ar';

  return (
    <nav id="menu" className={`navbar navbar-default navbar-fixed-top ${isArabic ? 'rtl' : ''}`}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className={`navbar-toggle ${isCollapsed ? "collapsed" : ""}`}
            onClick={toggleNavbar}
            aria-expanded={!isCollapsed}
          // data-toggle="collapse"
          // data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link to="/" onClick={closeNavbar} className="title">
            <img className="logo" src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" />
            <span className="logo-text">
              EECR
            </span>
          </Link>
        </div>
        <div
          className={`collapse navbar-collapse ${isCollapsed ? "" : "in"}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className={`nav navbar-nav navbar-right ${isArabic ? 'text-right' : ''}`}>
            <li>
              <Link to="/" onClick={closeNavbar} className="nav-text">{t('home')}</Link>
            </li>
            <li>
              <Link to="/faq" onClick={closeNavbar} className="page-scroll nav-text">{t('faq')}</Link>
            </li>
            <li>
              <Link to="/about" onClick={closeNavbar} className="page-scroll nav-text">{t('about')}</Link>
            </li>
            <li>
              <Link to="/contact" onClick={closeNavbar} className="page-scroll nav-text">{t('contact')}</Link>
            </li>
          </ul>
        </div>
        <button className="language-switcher" onClick={toggleLanguage}>
          {i18n.language === 'en' ? 'عربي' : 'Eng'}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
