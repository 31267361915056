import React, { useState } from "react";
import "../styles/Hero.css";
import { useTranslation } from 'react-i18next';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Hero = () => {
  const [animationKey, setAnimationKey] = useState(Date.now()); // Track the animation reset key
  const { t } = useTranslation();

  const slides = [
    { id: 1, image: "img/bg-img/hero1.jpg", text: t('hero1') },
    { id: 2, image: "img/bg-img/hero2.jpg", text: t('hero2') },
    { id: 3, image: "img/bg-img/hero3.jpg", text: t('hero3') },
    { id: 4, image: "img/bg-img/hero4.jpg", text: t('hero4') },
    { id: 5, image: "img/bg-img/hero5.jpg", text: t('hero5') },
  ];

  // Trigger the animation reset on slide change
  const handleChange = () => {
    setAnimationKey(Date.now()); // This will force the animation to reset on every slide change
  };

  return (
    <section className="hero-area">
      <Carousel
        autoPlay
        infiniteLoop
        interval={3000}
        showThumbs={false}
        showStatus={false}
        swipeable={window.innerWidth > 768}
        transitionTime={600} // Adjust the transition speed of the slide
        emulateTouch
        onChange={handleChange} // Trigger animation on slide change
      >
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="single-hero-slide bg-img"
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            <div className="container h-100">
              <div className="row h-100 align-items-center">
                <div className="col-12">
                  <div className="hero-slides-content">
                    <h2 key={animationKey} className="slide-heading">
                      {slide.text}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default Hero;
