import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollTop';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import BuildingDetailsPage from './pages/BuildingDetailsPage';
import UnitDetailsPage from './pages/UnitDetailsPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import "./App.css";
import Faq from './pages/Faq';


function App() {
    return (
        <Router>
            <div className="app-container">
                <Navbar />
                <div className="content-container">
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/buildings/:buildingId" element={<BuildingDetailsPage />} />
                        <Route path="/units/:unitId" element={<UnitDetailsPage />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
