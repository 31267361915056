import React, { useState } from "react";
import { motion } from "framer-motion";
import "../styles/Contact.css";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

function Contact() {
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => {
        const { fullName, email, phone, message } = formData;

        if (!fullName || fullName.length < 4 || /\d/.test(fullName)) {
            toast.error(t('validation.fullname'));
            return false;
        }
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error(t('validation.email'));
            return false;
        }
        if (phone && !/^\d+$/.test(phone)) {
            toast.error(t('validation.phone'));
            return false;
        }
        if (!message || message.length < 4) {
            toast.error(t('validation.message'));
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true); // Start loading
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    full_name: formData.fullName,
                    email: formData.email,
                    phone: formData.phone,
                    message: formData.message,
                }),
            });

            if (response.ok) {
                toast.success(t("validation.success"));
                setFormData({
                    fullName: "",
                    email: "",
                    phone: "",
                    message: "",
                });
            } else {
                const errorData = await response.json();
                toast.error(errorData.detail || t("validation.error"));
            }
        } catch (error) {
            toast.error(t("validation.error"));
            console.error("Error submitting the form:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const isArabic = i18n.language === "ar";

    return (
        <div className="contact-us">
            <ToastContainer />
            {/* Hero Section */}
            <motion.div
                className="hero-section"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <div className="hero-text">
                    <h3>
                        {t('contact-title1')} <span className="green-text">{t('contact-title2')}</span>
                    </h3>
                    <p className="fade-text">{t('contact-desc')}</p>
                </div>
                <motion.div
                    className="hero-image-wrapper"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/img/contactUs.png`}
                        alt="Contact Us"
                        className="hero-image"
                    />
                    <motion.div
                        className="call-us"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                        whileHover={{ scale: 1.1, y: -5 }}
                        onClick={() => (window.location.href = "tel:+96170435113")}
                    >
                        {t('contact-call')} <strong>70435113</strong>
                    </motion.div>
                </motion.div>
            </motion.div>

            {/* Contact Form and Info */}
            <motion.div
                className={`contact-content ${isArabic ? "rtl" : ""}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.4 }}
            >
                <motion.div
                    className={`contact-form ${isArabic ? "rtl" : ""}`}
                    initial={{ x: -100 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <h2>
                        {t('message-title1')} <span className="green-text">{t('message-title2')}</span>
                    </h2>
                    <p className="fade-text">
                        {t('message-desc')}
                    </p>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="fullName"
                            placeholder={t('message-name')}
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder={t('message-email')}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="phone"
                            placeholder={t('message-phone')}
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        <textarea
                            name="message"
                            placeholder={t('message-message')}
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                        <button type="submit" disabled={loading}>
                            {loading ? (
                                <ClipLoader size={20} color="#fff" />
                            ) : (
                                t("message-button")
                            )}
                        </button>
                    </form>
                </motion.div>

                <motion.div
                    className={`contact-info ${isArabic ? "rtl" : ""}`}
                    initial={{ x: 100 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    {/* <div className="office-location">
                        <h2>
                            {t('location-title1')} <span className="green-text">{t('location-title2')}</span>
                        </h2>
                        <p>{t('location-location')}</p>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13243.40278685812!2d36.0675!3d34.57044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1521804c4444c5d7%3A0xa5e0734f9168f9d3!2sHalba%2C%20Lebanon!5e0!3m2!1sen!2sus!4v1686859329953!5m2!1sen!2sus"
                            width="100%"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Map of Halba, Akkar, Lebanon"
                        ></iframe>
                        <button
                            className="open-maps-button"
                            onClick={() =>
                                window.open(
                                    'https://www.google.com/maps?q=34.57044,36.0675',
                                    '_blank'
                                )
                            }
                        >
                            {t('location-button')}
                        </button>
                    </div> */}
                    <div className="office-hours">
                        <h2>
                            {t('hours-title1')} <span className="green-text">{t('hours-title2')}</span>
                        </h2>
                        <ul>
                            <li>{t('hours-day1')} <span className="timeopen">9:00 {t('am')} - 6:00 {t('pm')}</span></li>
                            <li>{t('hours-day2')} <span className="timeopen">10:00 {t('am')} - 4:00 {t('pm')}</span></li>
                            <li>{t('hours-day3')} <span className="timeclose">{t('hours-close')}</span></li>
                        </ul>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
}

export default Contact;
