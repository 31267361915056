import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { FaBuilding, FaMapMarkerAlt, FaMoneyBillAlt, FaRoad } from 'react-icons/fa'; // Icons
import "../styles/BuildingDetails.css"; // Your styling for the building details page

const BuildingDetailsPage = () => {
    const { buildingId } = useParams(); // Get the building ID from the URL
    const [building, setBuilding] = useState(null);
    const [isMuted, setIsMuted] = useState(true); // State to track mute status
    const videoRef = useRef(null); // Reference to the video element
    const { t, i18n } = useTranslation();


    const floorOrder = {
        underground: 0,
        ground: 1,
        first: 2,
        second: 3,
        third: 4,
        fourth: 5,
        fifth: 6,
        sixth: 7,
    };

    const floorTranslations = {
        underground: t('underground-floor'),
        ground: t('ground-floor'),
        first: t('first-floor'),
        second: t('second-floor'),
        third: t('third-floor'),
        fourth: t('fourth-floor'),
        fifth: t('fifth-floor'),
        sixth: t('sixth-floor'),
    };

    useEffect(() => {
        if (buildingId) {
            fetch(`${process.env.REACT_APP_API_URL}/buildings/${buildingId}/`) // Fetch building details by ID
                .then((response) => response.json())
                .then((data) => {
                    const sortedUnits = data.units.sort(
                        (a, b) => floorOrder[a.apartment_floor.toLowerCase()] - floorOrder[b.apartment_floor.toLowerCase()]
                    );

                    setBuilding({ ...data, units: sortedUnits });
                })
                .catch((error) => console.error('Error fetching building details:', error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingId]);

    if (!building) return <p>{t('loading')}</p>;
    // Toggle mute/unmute functionality
    const handleMuteToggle = () => {
        setIsMuted(!isMuted);
        if (videoRef.current) {
            videoRef.current.muted = !isMuted;
        }
    };
    const isArabic = i18n.language === 'ar';
    return (
        <div className="building-details-page" dir={isArabic ? 'rtl' : 'ltr'} lang={i18n.language}>
            {/* Video Section */}
            {building.video_url && (
                <div className="building-video">
                    <video
                        ref={videoRef}
                        width="100%"
                        height="auto"
                        loop
                        autoPlay
                        muted={isMuted} // Controlled by the state
                    >
                        <source src={`${process.env.REACT_APP_API_URL}${building.video_url}`} type="video/mp4" />
                        {t('video-error')}
                    </video>

                    {/* Mute/Unmute Button */}
                    <button className="mute-button" onClick={handleMuteToggle}>
                        <i
                            className={`fas ${isMuted ? "fa-volume-mute" : "fa-volume-up"}`} // Toggle icon based on mute state
                        ></i>
                    </button>
                </div>
            )}

            {/* Building Name and Description */}
            <div className="building-info">
                <h1>{isArabic ? building.name_arb : building.name}</h1>
                <p>{isArabic ? building.description_arb : building.description}</p>
            </div>

            {/* Image Carousel */}
            <div className="image-carousel">
                <Carousel>
                    {building.building_images && building.building_images.map((image, index) => (
                        <div key={index}>
                            <img
                                src={`${process.env.REACT_APP_API_URL}${image.image}`}
                                alt={`${building.name} - ${index + 1}`}
                                className="carousel-image"
                            />
                        </div>
                    ))}
                </Carousel>
            </div>

            {/* Info Cards */}
            <div className="info-cards">
                <div className="card">
                    <FaBuilding className="floor-icon" />
                    <p>{building.nb_floor} {t('floors')}</p>
                </div>
                <div className="card">
                    <FaRoad className="road-icon" />
                    <p>{building.distance_from_road} {t('to-main-road')}</p>
                </div>
                <div className="card">
                    <FaMapMarkerAlt className="location-icon" />
                    <p>{isArabic ? building.location_arb : building.location}</p>
                </div>
                <div className="card">
                    <FaMoneyBillAlt className="price-icon" />
                    <p>{building.price_tag ? isArabic ? building.price_tag_arb : building.price_tag : t('no-price')}</p>
                </div>
            </div>
            <div className="building-map">
                {/* <h3>Building <span className="green-text">Location</span></h3> */}
                {building.latitude && building.longitude ? (
                    <>
                        <iframe
                            src={`https://www.google.com/maps?q=${building.latitude},${building.longitude}&hl=en&z=15&output=embed`}
                            width="100%"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Building Location"
                        ></iframe>
                        <button
                            className="open-maps-button"
                            onClick={() =>
                                window.open(
                                    `https://www.google.com/maps?q=${building.latitude},${building.longitude}`,
                                    '_blank'
                                )
                            }
                        >
                            {t('location-button')}
                        </button>
                    </>
                ) : (
                    <p>{t('no-location')}</p>
                )}
            </div>


            <div className="units-by-floor">
                {/* <h3>Building <span className="green-text">Units</span></h3> */}
                {Object.keys(floorOrder).map((floor) => {
                    const floorUnits = building.units.filter((unit) => unit.apartment_floor.toLowerCase() === floor);
                    return (
                        floorUnits.length > 0 && (
                            <div key={floor} className="floor-section">
                                <h3>{floorTranslations[floor]}</h3>
                                <div className="floor-units">
                                    {floorUnits.map((unit) => (
                                        <Link to={`/units/${unit.id}`} key={unit.id}>
                                            <div className="unit-card">
                                                <div className="unit-img-area">
                                                    <div className="image-slider">
                                                        {unit.unit_images && unit.unit_images.length > 0 ? (
                                                            <Carousel
                                                                showThumbs={false} // Disable thumbnails
                                                                autoPlay={true}    // Enable auto-play
                                                                infiniteLoop={true} // Infinite loop
                                                                interval={3000}    // Slide interval
                                                                showStatus={false} // Hide status
                                                                swipeable={window.innerWidth > 768}
                                                            >
                                                                {unit.unit_images.map((image, index) => (
                                                                    <div key={index}>
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}${image.image}`}
                                                                            alt={`Unit ${unit.id}`}
                                                                            className="building-image"
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Carousel>
                                                        ) : (
                                                            <p>{t('home-noimage')}</p>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={`unit-state ${unit.status === "available" ? "available" : ""}`}
                                                    >
                                                        {unit.status === "available" ? t('available') : t('not-available')}
                                                    </div>
                                                </div>
                                                <h5 className="unit-title">{unit.code}</h5>

                                                <div className="unit-meta-data">
                                                    {unit.number_of_bedrooms && (
                                                        <div className="meta-item">
                                                            <i className="fas fa-bed icon"></i>
                                                            <span className="meta-number">{unit.number_of_bedrooms}</span>
                                                        </div>
                                                    )}
                                                    {unit.number_of_bathrooms && (
                                                        <div className="meta-item">
                                                            <i className="fas fa-bath icon"></i>
                                                            <span className="meta-number">{unit.number_of_bathrooms}</span>
                                                        </div>
                                                    )}
                                                    {unit.parking_spaces_per_unit && (
                                                        <div className="meta-item">
                                                            <i className="fas fa-car icon"></i>
                                                            <span className="meta-number">{unit.parking_spaces_per_unit}</span>
                                                        </div>
                                                    )}
                                                    {unit.height && (
                                                        <div className="meta-item">
                                                            <i className="fas fa-ruler-vertical icon"></i>
                                                            <span className="meta-number">
                                                                {Number(unit.height).toFixed(0)} {t('unit-meters')}
                                                            </span>
                                                        </div>
                                                    )}
                                                    <div className="meta-item">
                                                        <i className="fas fa-ruler-combined icon"></i>
                                                        <span className="meta-number">
                                                            {Number(unit.total_square_meters).toFixed(0)} {t('sqm')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                                <hr />
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default BuildingDetailsPage;
