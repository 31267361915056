import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../styles/BuildingList.css";
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { FaMapMarkerAlt } from 'react-icons/fa';

const BuildingList = () => {
    const [buildings, setBuildings] = useState([]);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/buildings/`)
            .then((response) => response.json())
            .then((data) => setBuildings(data))
            .catch((error) => console.error('Error fetching buildings:', error));
    }, []);

    return (
        <div className="building-list-container" dir={isArabic ? 'rtl' : 'ltr'} lang={i18n.language}>
            <div className="building-list-header">
                <h2>{t('home-title1')} <span className="green-text">{t('home-title2')}</span></h2>
                <p className="sub-title">{t('home-desc')}</p>
            </div>
            <div className="building-list">
                {buildings.map((building) => (
                    <div key={building.id} className="building-card">
                        {building.available ? (
                            <Link to={`/buildings/${building.id}`}>
                                <BuildingCard building={building} isArabic={isArabic} />
                            </Link>
                        ) : (
                            <div className="building-card-disabled">
                                <BuildingCard building={building} isArabic={isArabic} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const BuildingCard = ({ building, isArabic }) => {
    const { t } = useTranslation();
    return (
        <div className="building-card-inner" >
            <div className="image-slider">
                {building.building_images.length > 0 ? (
                    <Carousel showThumbs={false} autoPlay infiniteLoop interval={3000} showStatus={false} swipeable={window.innerWidth > 768}>
                        {building.building_images.map((image, index) => (
                            <img
                                key={index}
                                src={`${process.env.REACT_APP_API_URL}${image.image}`}
                                alt={`${building.name} - ${index + 1}`}
                                className="building-image"
                            />
                        ))}
                    </Carousel>
                ) : (
                    <p>{t('home-noimage')}</p>
                )}
            </div>
            <div className={isArabic ? 'building-details rtl' : 'building-details'}>
                <h5 className="building-name" style={{ textAlign: isArabic ? 'right' : 'left' }}>
                    {isArabic ? building.name_arb : building.name}
                </h5>
                <div className="price-range" >
                    <p style={{ textAlign: isArabic ? 'right' : 'left' }}>
                        {isArabic ? building.price_tag_arb : building.price_tag}
                    </p>
                </div>
                <p className="location"><FaMapMarkerAlt /> {isArabic ? building.location_arb : building.location}</p>
                <p className="description" style={{ textAlign: isArabic ? 'right' : 'left' }}>
                    {isArabic ? building.description_arb : building.description}
                </p>
            </div>
        </div>
    );

};

export default BuildingList;
