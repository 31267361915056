import React from "react";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import "../styles/About.css";
import { useTranslation } from 'react-i18next';

function About() {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const MotionLink = motion(Link);

    return (
        <div>
            <div className="about-us">
                {/* Hero Section */}
                <motion.div
                    className="about-hero-section"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <div className="about-hero-text">
                        <h3>
                            {t('about-title1')} <span className="green-text">{t('about-title2')}</span>
                        </h3>
                        <p className="fade-text">
                            {t('about-desc')}
                        </p>
                    </div>
                    <motion.div
                        className="about-hero-image-wrapper"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/img/aboutUs.png`}
                            alt="About Us"
                            className="about-hero-image"
                        />
                    </motion.div>
                </motion.div>

                {/* About Content */}
                <motion.div
                    className="about-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                >
                    <motion.div
                        className={`our-story ${isArabic ? 'rtl' : ''}`}
                        initial={{ x: isArabic ? 100 : -100 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/img/ourstory.png`}
                            alt="Our Story"
                            className="about-hero-image"
                        />
                        <h2>
                            {t('story-title1')} <span className="green-text">{t('story-title2')}</span>
                        </h2>
                        <p>
                            {t('story-desc')}
                        </p>
                    </motion.div>

                    <motion.div
                        className={`our-mission ${isArabic ? 'rtl' : ''}`}
                        initial={{ x: isArabic ? -100 : 100 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/img/mission.png`}
                            alt="Mission"
                            className="about-hero-image"
                        />
                        <h2>
                            {t('mission-title1')} <span className="green-text">{t('mission-title2')}</span>
                        </h2>
                        <p>
                            {t('mission-desc')}
                        </p>
                    </motion.div>
                </motion.div>
            </div>
            <motion.div
                className={`get-in-touch-section ${isArabic ? 'rtl' : ''}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                style={{ direction: isArabic ? 'rtl' : 'ltr', textAlign: isArabic ? 'right' : 'left' }}
            >
                <div className="text-content">
                    <h2>{t('touch-title1')} <span className="whitegreen-text">{t('touch-title2')}</span></h2>
                    <p>
                        {t('touch-desc')}
                    </p>
                </div>
                <MotionLink
                    to="/contact"
                    className={`contact-button ${isArabic ? 'rtl' : ''}`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    style={{ direction: isArabic ? 'rtl' : 'ltr', textAlign: isArabic ? 'right' : 'left' }}
                >
                    {t('touch-button')}
                </MotionLink>
            </motion.div>
        </div>
    );
}

export default About;
