import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import '../styles/Faq.css';
import { useTranslation } from 'react-i18next';

function Faq() {
    const [faqs, setFaqs] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        // Fetch FAQ data from the API
        fetch(`${process.env.REACT_APP_API_URL}/api/faqs/`)
            .then((response) => response.json())
            .then((data) => setFaqs(data))
            .catch((error) => console.error('Error fetching FAQs:', error));
    }, []);

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const isArabic = i18n.language === 'ar';
    return (
        <div className="faq-section" dir={isArabic ? 'rtl' : 'ltr'} lang={i18n.language}>
            <motion.div
                className="faq-title"
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
            >
                <h1>
                {t('faq-title1')}<span className="green-text"> {t('faq-title2')}</span>
                </h1>
            </motion.div>

            <motion.div
                className="faq-container"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.8 }}
                style={{ display: 'block' }}
            >
                {faqs.map((faq, index) => (
                    <div
                        key={faq.id}
                        className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                    >
                        <div className="faq-question" onClick={() => toggleAnswer(index)}>
                            <div className="question">
                            {isArabic ? faq.question_ar : faq.question_en}
                            </div>
                            <div className={`hamburger-${index} hamburger ${activeIndex === index ? 'open' : ''}`}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <AnimatePresence>
                        {activeIndex === index && (
                            <div className="faq-answer">
                                {isArabic ? faq.answer_ar : faq.answer_en}
                            </div>
                        )}
                        </AnimatePresence>
                    </div>
                ))}
            </motion.div>
        </div>
    );
}

export default Faq;
