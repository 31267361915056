import React from "react";
import "../styles/Footer.css";

function Footer() {
    return (
        <div>
            <footer className="footer-distributed">
                {/* Left Section */}
                <div className="footer-left">
                    <div className="footer-logo">
                        <img className="logo-foot" src={`${process.env.PUBLIC_URL}/logo1.png`} alt="logo" />
                        <span className="logo-text-foot">EECR</span>
                    </div>
                    <div className="footer-contact">
                        <p>
                            <i className="fas fa-phone-alt"></i>
                            <a href="tel:+96170435113">+961 70435113</a>
                        </p>
                        {/* <p><a href="mailto:office@template.com">wissam@gmail.com</a></p> */}
                    </div>
                </div>

                {/* Center Section */}
                <div className="footer-center">
                    <div className="footer-hours">
                        <p className="first">Mon - Fri:</p><p>9AM - 6PM</p>
                        <p className="first">Sat:</p><p>10AM - 4PM</p>
                        <p className="first">Sun:</p><p> Closed</p>
                    </div>
                </div>

                {/* Right Section */}
                <div className="footer-right">
                    {/* <a href="#" rel="noreferrer"><i className="fab fa-linkedin"></i></a> */}
                    <a href="https://www.instagram.com/eecrealestate/" id="insta" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href="https://www.tiktok.com/@eecrealestat" id="tiktok" target="_blank" rel="noreferrer"><i className="fab fa-tiktok"></i></a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
