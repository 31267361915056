import React from 'react';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import BuildingList from '../components/BuildingList'
import '../styles/Home.css';

function Home() {
  const { t } = useTranslation();

  return (
    <div>
      <Hero />
      <BuildingList />
      <div className="coming-soon-section">
        <div className="content">
          <h2>{t('home-soon')}</h2>
          <p>{t('home-soon-desc')}</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
